import api from "../service/http/api.service.js"

export const ProfileService = {

  async fetchProfile() {
    return await api.get('/user/profile');
  },

  async updateProfile(profileData) {
    return await api.put('/user/profile', profileData);
  },

  async deauthorize() {
    return await api.post('/user/disconnectStrava');
  },

  async getLastUpdated() {
    return await api.get('/user/lastUpdated')
  },

}

