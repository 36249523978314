/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts';

// Composables
import {createApp} from 'vue'
// Plugins
import {registerPlugins} from '@/plugins'
import router from "@/router";
import {createVuetify} from 'vuetify';
import DateFnsAdapter from '@date-io/date-fns';
import 'vuetify/styles'; // Required for Vuetify components
import '@/assets/css/global.css'; // Import global styles for additional customization

// Custom theme definition
const customTheme = {
  dark: false,
  colors: {
    primary: '#1976D2', // Primary color
    secondary: '#424242', // Secondary color
    surface: '#FFFFFF', // Background color for components
    tableHeader: '#f5f5f5', // Custom table header background
    tableAltRow: '#f9f9f9', // Custom alternate row color
  },
};

const dateAdapter = new DateFnsAdapter();

const vuetify = createVuetify({
  date: {
    adapter: dateAdapter,
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
  /*display: {
    thresholds: {
      xs: 480, // Adjust xs to 480px
      sm: 768,
      md: 1200,
      lg: 1920,
    },
    mobileBreakpoint: 'sm', // Define the breakpoint for mobile
  },*/
});


const app = createApp(App)

app.use(VueApexCharts);
app.component('apexchart', VueApexCharts);

// convert mt to km
app.config.globalProperties.$convertToKilometers = meters => {
  return parseFloat((meters / 1000).toFixed(2));
};

// convert m/s to kmph
app.config.globalProperties.$convertToKmph = mps => (mps * 3.6).toFixed(2);

// convert m/s to pace in min/km
// In your global config or utility file
app.config.globalProperties.$convertToPaceFloat = (mps, type = 'km') => {
  if (mps <= 0) {
    return '0.00';
  }
  if (type === 'km') {
    // Convert m/s to min/km
    return (60 / (mps * 3.6)).toFixed(2);
  } else if (type === '100m') {
    // Convert m/s to min/100m
    return (100 / mps / 60).toFixed(2);
  }
  return 0.00;
};

app.config.globalProperties.$convertToPace = (mps, type = 'km') => {
  if (mps <= 0) {
    return '00:00:00';
  }

  let totalSeconds;
  if (type === 'km') {
    // Convert m/s to seconds per km
    totalSeconds = 1000 / mps;
  } else if (type === '100m') {
    // Convert m/s to seconds per 100m
    totalSeconds = 100 / mps;
  } else {
    return '00:00:00';
  }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Return formatted string in hh:mm:ss format
  // Format minutes and seconds
  const formattedMinutes = String(minutes);
  const formattedSeconds = String(seconds).padStart(2, '0');

  // Return formatted string
  return hours > 0
    ? `${hours}:${formattedMinutes}:${formattedSeconds}` // Include hours if > 0
    : `${formattedMinutes}m ${formattedSeconds}s`;        // Omit hours if 0

};


// Format Numbers to 2 decimal places or none
app.config.globalProperties.$formatNumber = (value, dp = 2) => {
  if (!value && value !== 0) return '';
  const floatValue = parseFloat(value);
  if (floatValue % 1 === 0) {
    return floatValue.toFixed(dp);
  } else {
    return floatValue.toFixed(dp);
  }
};

app.config.globalProperties.$formatNumberToFloat = value => {
  if (!value && value !== 0) return '';  // Return an empty string if value is null or undefined

  const floatValue = parseFloat(value);
  if (floatValue % 1 === 0) {
    // For integers, parse as integer
    return parseInt(floatValue.toFixed(0), 10);
  } else {
    // For floats, parse as float
    return parseFloat(floatValue.toFixed(2));
  }
};


// convert seconds to hh mm format
app.config.globalProperties.$formatSecondsToHHMM = totalSeconds => {

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Return formatted string in hh:mm:ss format
  // Format minutes and seconds
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  // Return formatted string
  return hours > 0
    ? `${hours}:${formattedMinutes}:${formattedSeconds}` // Include hours if > 0
    : `${formattedMinutes}:${formattedSeconds}`;        // Omit hours if 0


  /*const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return hours > 0
    ? `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m` // Include hours if > 0
    : `${minutes.toString().padStart(2, '0')}m`;        // Omit hours if 0*/

  //return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m`;
};


registerPlugins(app)

app.use(vuetify); // Add Vuetify to the app
app.use(router)
app.mount('#app')
