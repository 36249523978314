export default [
  {
    path: 'request/:token/:action',
    name: 'request-action',
    component: () => import( '@/views/joinRequest/requestPage.vue'),
    meta: {requiresAuth: true},
    props: {type: 'requests'}
  },
  {
    path: 'requests',
    name: 'pending-requests',
    component: () => import( '@/views/joinRequest/requestPage.vue'),
    meta: {requiresAuth: true},
    props: {type: 'requests'}
  },
  {
    path: 'invites',
    name: 'pending-invites',
    component: () => import( '@/views/joinRequest/requestPage.vue'),
    meta: {requiresAuth: true},
    props: {type: 'invites'}
  }
];
